<template>
  <UiTourDriver
    v-if="timeToCall"
    ref="driver"
    :tour="TOURS_IDS.SDR"
    :highlight
    :steps="steps"
    :type="TOURS_TYPES.NEW_FEATURE"
    @destroyed="handleDestroy"
  />
</template>

<script setup lang="ts">
import type { DriveStep } from 'driver.js'
import { secondsToMinutes } from 'date-fns'
import type { UiTourDriver } from '#build/components'
import { STAGES, TOURS_IDS, TOURS_TYPES } from '~/constants'
import { useUiStore } from '~/store/ui'
import { useAuthStore } from '~/store/auth'

const driver = ref<InstanceType<typeof UiTourDriver> | null>(null)

const authStore = useAuthStore()

const highlight = ref<DriveStep>({
  element: '#pipelines-queue',
  popover: {
    title: 'SDR queue',
    description: `Introducing our hot new SDR queue! Now you'll snag incoming leads on the fly, 
        feeling the pulse of opportunity in real-time 😉`,
    side: 'bottom',
    align: 'start',
  },
})

const createTimeInQueueBlock = () => {
  const timeBlock = document.querySelector('[id^="created_at_"]')
  const actionsBlock = document.querySelector('[id^="actions_"]')

  if (timeBlock && actionsBlock) {
    const { x, y, width, height } = timeBlock.getBoundingClientRect()
    const { width: actionsWidth } = actionsBlock.getBoundingClientRect()

    const tempBlock = document.createElement('div')

    tempBlock.id = 'time_in_queue_temp_block'

    if (document.querySelector('#time_in_queue_temp_block')) {
      // Block already exists
      return
    }

    tempBlock.style.cssText = `
      height: ${height}px; 
      left: ${x}px; 
      position: absolute; 
      top: ${y}px; 
      width: ${width + actionsWidth}px; 
      pointer-events: none;
    `

    document.body.appendChild(tempBlock)
  }
}

const createStagesBlock = () => {
  const stagesBlock = document.querySelector('#stages')

  if (!stagesBlock) {
    throw new Error('Stages block not found')
  }

  const stages = Array.from(stagesBlock.children)

  const firstBlock = stages[0]
  const firstBlockHeader = firstBlock?.querySelector('div')

  if (!firstBlockHeader) {
    throw new Error('Header block not found')
  }

  const { width } = stagesBlock.getBoundingClientRect()
  const { x } = firstBlock.getBoundingClientRect()
  const { y, height } = firstBlockHeader.getBoundingClientRect()

  const tempBlock = document.createElement('div')

  tempBlock.id = 'stages_temp_block'

  if (document.querySelector('#stages_temp_block')) {
    // Block already exists
    return
  }

  tempBlock.style.cssText = `
      height: ${height}px; 
      left: ${x}px; 
      position: absolute; 
      top: ${y}px; 
      width: ${width}px; 
      pointer-events: none;
    `

  document.body.appendChild(tempBlock)
}

const toggleActionsBlock = () => {
  const tableQueue = document.querySelector('#tableQueue')

  if (tableQueue) {
    // Click on slot item
    const actions = tableQueue.querySelector('#menu_actions_1 div')

    if (actions) {
      ;(actions as HTMLElement).click()
    }
  }
}

const LEAD_QUEUE_TIME_TO_RETURN_TO_QUEUE_SECONDS = 'lead_queue_time_to_return_to_queue_seconds'

const timeMakeFirstCall = ref(0)
const isTimeCallEnabled = ref(false)

const getSdrQueueSettings = async () => {
  try {
    const {
      // @ts-ignore
      settings: {
        lead_queue_time_to_return_to_queue_seconds: timeCallSetting,
        lead_queue_time_to_return_to_queue_seconds_checkbox: timeCallEnabled,
      },
    } = await useGetSettingsByCode(LEAD_QUEUE_TIME_TO_RETURN_TO_QUEUE_SECONDS)

    timeMakeFirstCall.value = Number(timeCallSetting)
    isTimeCallEnabled.value = !!timeCallEnabled
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  }
}

const showNavigationMenu = useCookie('showNavigationMenu')

onMounted(() => {
  getSdrQueueSettings()

  if (!showNavigationMenu.value) {
    showNavigationMenu.value = true
  }
})

const timeToCall = computed(() => {
  if (!timeMakeFirstCall.value) {
    return false
  }

  return timeMakeFirstCall.value >= 60
    ? `${secondsToMinutes(timeMakeFirstCall.value)} min`
    : `${timeMakeFirstCall.value} sec`
})

const isLeadActionsMounted = useState('isLeadActionsMounted', () => false)
const isQueueTableLoaded = useState('isQueueTableLoaded', () => false)
const isSalesAllTableLoaded = useState('isSalesAllTableLoaded', () => false)
const isPopupMounted = useState('isPopupMounted', () => false)
const isAgentSelectInActivityVisible = useState('isAgentSelectInActivityVisible', () => false)

const uiStore = useUiStore()

const steps = computed<DriveStep[]>(() => {
  return authStore.getIsSdr
    ? [
        {
          element: '#pipelines-queue',
          popover: {
            title: '1/7 SDR flow',
            description: `Welcome to Queue - your fresh incoming leads! 
              Leads are prepare for you by language and ready for swift action 🔥`,
            side: 'bottom',
            align: 'start',
            onNextClick: async () => {
              useMittEvent('tour:setSDRQueueMock')

              await nextTick()

              createTimeInQueueBlock()

              if (driver.value?.driverObj) {
                driver.value.driverObj.moveNext()
              }
            },
          },
        },
        {
          element: '#time_in_queue_temp_block',
          popover: {
            title: '2/7 SDR flow',
            description: `If an SDR doesn't grab a lead within 15 minutes, it gets passed to agents rotation. 
              Ready to dive in? Pick it up and dial away! 🔥`,
            side: 'bottom',
            align: 'start',
            onNextClick: async () => {
              if (isTimeCallEnabled.value) {
                await navigateTo({
                  name: 'leads-id',
                  params: { id: '1' },
                  query: { tour: 'active' },
                })

                driver.value?.toggleTourInterface()

                await until(isLeadActionsMounted).toBe(true, {
                  timeout: 3000,
                })

                await nextTick()

                if (driver.value?.driverObj) {
                  driver.value.driverObj.moveNext()
                }

                driver.value?.toggleTourInterface()
              } else {
                if (!uiStore.salesPipelineGridView) {
                  uiStore.toggleSalesPipelineGridView()
                }

                await navigateTo({
                  name: 'pipelines-sales',
                })

                driver.value?.toggleTourInterface()

                await until(isSalesAllTableLoaded).toBe(true, {
                  timeout: 3000,
                })

                await nextTick()

                await new Promise((resolve) => setTimeout(resolve, 300))

                createStagesBlock()

                await nextTick()

                if (driver.value?.driverObj) {
                  driver.value.driverObj.moveNext()
                }

                driver.value?.toggleTourInterface()
              }
            },
          },
        },
        isTimeCallEnabled.value
          ? {
              element: '#timer-for-call',
              popover: {
                title: '3/7 SDR flow',
                description: `Once you've picked up a lead, you've got just ${timeToCall.value} to make that call. 
              Miss the window? No worries, it'll bounce back to the queue. 
              One lead, one active timer. Let's make every second count! 💥`,
                side: 'bottom',
                align: 'end',
                onPrevClick: async () => {
                  await navigateTo({
                    name: 'pipelines-queue',
                  })

                  await until(isQueueTableLoaded).toBe(true, {
                    timeout: 3000,
                  })

                  await nextTick()

                  useMittEvent('tour:setSDRQueueMock')

                  if (driver.value?.driverObj) {
                    driver.value.driverObj.movePrevious()
                  }
                },
                onNextClick: async () => {
                  if (!uiStore.salesPipelineGridView) {
                    uiStore.toggleSalesPipelineGridView()
                  }

                  await navigateTo({
                    name: 'pipelines-sales',
                  })

                  driver.value?.toggleTourInterface()

                  await until(isSalesAllTableLoaded).toBe(true, {
                    timeout: 3000,
                  })

                  await nextTick()

                  await new Promise((resolve) => setTimeout(resolve, 300))

                  createStagesBlock()

                  await nextTick()

                  if (driver.value?.driverObj) {
                    driver.value.driverObj.moveNext()
                  }

                  driver.value?.toggleTourInterface()
                },
              },
            }
          : undefined,
        {
          element: '#stages_temp_block',
          popover: {
            title: '4/7 SDR flow',
            description: `After the call, your lead stays in your pipeline until you finish qualifying it. 
              Keep the momentum going! 💪`,
            side: 'bottom',
            align: 'start',
            onPrevClick: async () => {
              await navigateTo({
                name: 'leads-id',
                params: { id: '1' },
                query: { tour: 'active' },
              })

              driver.value?.toggleTourInterface()

              await until(isLeadActionsMounted).toBe(true, {
                timeout: 3000,
              })

              await nextTick()

              if (driver.value?.driverObj) {
                driver.value.driverObj.movePrevious()
              }

              driver.value?.toggleTourInterface()
            },
            onNextClick: async () => {
              await navigateTo({
                name: 'leads-id',
                params: { id: '1' },
                query: { tour: 'active' },
              })

              driver.value?.toggleTourInterface()

              useMittEvent('tour:SDRQueue:createActivity')

              await until(isAgentSelectInActivityVisible).toBe(true, {
                timeout: 3000,
              })

              await new Promise((resolve) => setTimeout(resolve, 500))

              await nextTick()

              if (driver.value?.driverObj) {
                driver.value.driverObj.moveNext()
              }

              driver.value?.toggleTourInterface()
            },
          },
        },
        {
          element: '#activity-types-container',
          popover: {
            title: '5/7 SDR flow',
            description: `When you've agreed on a call time with the client, 
              connect them with an agent for a joint meeting before reassignment. 
              This action is accessible in the activity tab.`,
            side: 'left',
            align: 'start',
            onPrevClick: async () => {
              uiStore.cleanSidePanel()

              driver.value?.toggleTourInterface()

              await navigateTo({
                name: 'pipelines-sales',
              })

              await until(isSalesAllTableLoaded).toBe(true, {
                timeout: 3000,
              })

              await nextTick()

              await new Promise((resolve) => setTimeout(resolve, 300))

              createStagesBlock()

              await nextTick()

              if (driver.value?.driverObj) {
                driver.value.driverObj.movePrevious()
              }

              driver.value?.toggleTourInterface()
            },
            onNextClick: () => {
              uiStore.cleanSidePanel()

              if (driver.value?.driverObj) {
                driver.value.driverObj.moveNext()
              }
            },
          },
        },
        {
          element: '#won_lost_buttons',
          popover: {
            title: '6/7 SDR flow',
            description: `When it's time to qualify and reassign the lead to an agent, 
              you have two options: the classic 'Re-assign' action or simply click 'Qualify'.`,
            side: 'bottom',
            align: 'start',
            onPrevClick: async () => {
              driver.value?.toggleTourInterface()

              useMittEvent('tour:SDRQueue:createActivity')

              await until(isAgentSelectInActivityVisible).toBe(true, {
                timeout: 3000,
              })

              await new Promise((resolve) => setTimeout(resolve, 500))

              await nextTick()

              if (driver.value?.driverObj) {
                driver.value.driverObj.movePrevious()
              }

              driver.value?.toggleTourInterface()
            },
            onNextClick: async () => {
              useMittEvent('lead:reassign')

              await until(isPopupMounted).toBe(true, {
                timeout: 3000,
              })

              if (driver.value?.driverObj) {
                driver.value.driverObj.moveNext()
              }
            },
          },
        },
        {
          element: '#popup',
          popover: {
            title: '7/7 SDR flow',
            description: `After qualifying the lead through a shared meeting or assigning it to an agent, 
              move it to the 'Interested' stage. The lead will then shift to the agent's pipeline, marked 'Qualified by you'.`,
            side: 'left',
            align: 'start',
            onPrevClick: () => {
              uiStore.cleanPopup()

              if (driver.value?.driverObj) {
                driver.value.driverObj.movePrevious()
              }
            },
          },
        },
      ].filter(Boolean)
    : [
        {
          element: '#pipelines-queue',
          popover: {
            title: '1/5 SDR flow',
            description: `Welcome to Queue - your fresh incoming leads! 
              Leads are prepare for you by language and ready for swift action 🔥`,
            side: 'bottom',
            align: 'start',
            onNextClick: async () => {
              useMittEvent('tour:setSDRQueueMock')

              await nextTick()

              toggleActionsBlock()

              await nextTick()

              if (driver.value?.driverObj) {
                driver.value.driverObj.moveNext()
              }
            },
          },
        },
        {
          element: '#menu_actions_1_actions',
          popover: {
            title: '2/5 SDR flow',
            description: `You can manually assign lead or move to rotation from queue.
              SDR can pick-up lead within 15 minutes or it will be removed from the queue and assigned to agents.`,
            side: 'left',
            align: 'start',
            onNextClick: async () => {
              await navigateTo({
                name: 'leads-id',
                params: { id: '1' },
                query: { tour: 'active' },
              })

              driver.value?.toggleTourInterface()

              await until(isLeadActionsMounted).toBe(true, {
                timeout: 3000,
              })

              await nextTick()

              await new Promise((resolve) => setTimeout(resolve, 300))

              if (driver.value?.driverObj) {
                driver.value.driverObj.moveNext()
              }

              driver.value?.toggleTourInterface()
            },
          },
          onDeselected: () => {
            toggleActionsBlock()
          },
        },
        isTimeCallEnabled.value
          ? {
              element: '#timer-for-call',
              popover: {
                title: '3/5 SDR flow',
                description: `After picking up the lead, SDR has ${timeToCall.value} to start the call, 
              also he can have only 1 lead with an active timer at once.
              This focuses him on quick and sequential work with leads.`,
                side: 'bottom',
                align: 'end',
                onPrevClick: async () => {
                  await navigateTo({
                    name: 'pipelines-queue',
                  })

                  await until(isQueueTableLoaded).toBe(true, {
                    timeout: 3000,
                  })

                  await nextTick()

                  useMittEvent('tour:setSDRQueueMock')

                  await new Promise((resolve) => setTimeout(resolve, 300))

                  toggleActionsBlock()

                  await new Promise((resolve) => setTimeout(resolve, 300))

                  if (driver.value?.driverObj) {
                    driver.value.driverObj.movePrevious()
                  }
                },
              },
            }
          : undefined,
        {
          element: '#won_lost_buttons',
          popover: {
            title: '4/5 SDR flow',
            description: `To re-assign lead to an agent, SDR can choose the usual "Re-assign" 
              action, click on "Qualify" or create shared activity between lead and agent.`,
            side: 'bottom',
            align: 'start',
            onNextClick: async () => {
              if (!uiStore.salesPipelineGridView) {
                uiStore.toggleSalesPipelineGridView()
              }

              await navigateTo({
                name: 'pipelines-sales',
                query: { mock: 'true' },
              })

              driver.value?.toggleTourInterface()

              await until(isSalesAllTableLoaded).toBe(true, {
                timeout: 3000,
              })

              await new Promise((resolve) => setTimeout(resolve, 500))

              await nextTick()

              if (driver.value?.driverObj) {
                driver.value.driverObj.moveNext()
              }
            },
          },
        },
        {
          element: `[data-lead-stage="${STAGES.INTERESTED}"]`,
          popover: {
            title: '5/5 SDR flow',
            description: `If SDR assigned lead to an agent as "Qualified", 
              it will move to the agent's Interested column with "Qualified by SDR" tag.`,
            side: 'left',
            align: 'start',
            onPrevClick: async () => {
              await navigateTo({
                name: 'leads-id',
                params: { id: '1' },
                query: { tour: 'active' },
              })

              driver.value?.toggleTourInterface()

              await until(isLeadActionsMounted).toBe(true, {
                timeout: 3000,
              })

              await nextTick()

              await new Promise((resolve) => setTimeout(resolve, 300))

              if (driver.value?.driverObj) {
                driver.value.driverObj.movePrevious()
              }

              driver.value?.toggleTourInterface()
            },
          },
        },
      ].filter(Boolean)
})

const route = useRoute()

const handleDestroy = () => {
  useMittEvent('tour:clearSDRQueueMock')

  uiStore.cleanSidePanel()
  uiStore.cleanPopup()

  if (route.name !== 'pipelines-queue') {
    navigateTo({ name: 'pipelines-queue' })
  }
}
</script>

<style scoped lang="scss"></style>
